// Require optional shared components
require(['componentLoader'], function (helper) {
  // Make these modules dependencies
  helper.promote([
    'carousel',
    'carouselSet',
    'productPageSlider',
    'sliderSet'
  ]);

  helper.requireDependencies();
});

// This module's dependencies only
var deps = [
  'jquery',
  'productHeightFixer',
  'colourswatches',

  // Undeclared
  'colourswatchesTpl'
];

require(deps);
